<template>
  <base-dropdown title-classes="btn btn-secondary text-left">
    <template v-slot:title>
      <strong>{{months[selectedMonth]}}</strong>
    </template>
    <a
      class="dropdown-item" href="#" @click="monthSelected(index)"  v-for="(month, index) in months"
      :key="index">{{month}}</a>
  </base-dropdown>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      selectedMonth: moment().format('YYYY-MM'),
    };
  },
  mounted() {
    this.$emit('update:selectedMonth', this.selectedMonth);
  },
  computed: {
    months() {
      let data = {};
      for (let i = 0; i < 12; i++) {
        let tempDate = moment().subtract(i, 'months');
        let key = tempDate.format('YYYY-MM');
        data[key] = tempDate.format('MMMM YYYY') + (tempDate.isSameOrAfter( moment().format('YYYY-MM') + '-01') ? ' (Current)' : '');
      }
      return data;
    },
  },
  methods: {
    monthSelected(month) {
      this.selectedMonth = month;
      this.$emit('update:selectedMonth', month);
    },
  },
};
</script>
