<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Leading Indicator Report - {{venuespecialist_name}}</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6 m-b--6">
      <div class="row">
        <div class="col-xl-12">
          <template>
            <card>
              <div slot="header" class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0" id="lead_details_heading">Team Performance ({{$route.meta.country.toUpperCase()}})</h3>
                </div>
                <div class="col-4 text-right">
                  <custom-month-picker :selectedMonth.sync="selectedMonth" :key="$route.meta.country"></custom-month-picker>
                </div>
              </div>
              <h3>{{sales_rep.name}}</h3>
              <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="sales_rep.data" >
                <el-table-column label="" min-width="120px" prop="name" class="heading" >
                  <template v-slot="{ row }">
                    {{ row.date_name }}
                  </template>
                </el-table-column>

                <el-table-column label="Leads" prop="enquiries">
                  <template v-slot="{ row }">
                    {{ row.leads}}<br/>
                  </template>
                </el-table-column>

                <el-table-column label="Connections" prop="connections" min-width="100px" >
                  <template v-slot="{ row }">
                    {{ row.connections}} <strong>({{(row.leads ? (row.connections / row.leads * 100) : 0).toFixed(2) }}%)</strong>
                  </template>
                </el-table-column>

                <el-table-column label="Site Visits" prop="viewings_per_lead"  min-width="120px" >
                  <template v-slot="{ row }">
                    {{ row.viewings}} <strong>({{ (row.connections ? (row.viewings / row.connections * 100) : 0).toFixed(2) }}%)</strong>
                  </template>
                </el-table-column>

                <el-table-column label="Sales / Won %" prop="sales_won" min-width="120px"  >
                  <template v-slot="{ row }">
                    {{ row.sales_won}} <strong>({{ (row.viewings ? row.sales_won / row.viewings * 100 : 0).toFixed(2) }}%)</strong>
                  </template>
                </el-table-column>

                <el-table-column label="Conversion Rate" prop="funnel_conversion_rate" min-width="120px"  >
                  <template v-slot="{ row }">
                    {{ (row.connections ? (row.sales_won / row.connections * 100) : 0).toFixed(2) }}%
                  </template>
                </el-table-column>

                <el-table-column label="Visits Per Lead" prop="average_viewings_lead" min-width="120px"  >
                  <template v-slot="{ row }">
                    {{ (row.leads ? (row.viewings / row.leads) : 0).toFixed(2) }}
                  </template>
                </el-table-column>
              </el-table>
              <br/><br/>
            </card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import CustomMonthPicker from "@/views/Widgets/Reports/CustomMonthPicker.vue";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    CustomMonthPicker,
  },
  data() {
    return {
      venuespecialist: this.$route.params.venuespecialist,
      country: '',
      selectedMonth: '',
    };
  },
  computed: {
    sales_rep() {
      return this.$store.getters["reports/getLeadingSingleIndicatorReports"](
        this.$route.meta.country,
        this.selectedMonth,
        this.venuespecialist
      );
    },
    individualRouteName() {
      return 'SingleLeadingIndicatorReport' + $route.meta.country.toUpperCase();
    },
    venuespecialist_name() {
      return (this.sales_rep && this.sales_rep.name) ? this.sales_rep.name : '';
    },
  },
  watch: {
    selectedMonth() {
      this.$store.dispatch('reports/getLeadingIndicators', {
        country: this.$route.meta.country,
        month: this.selectedMonth,
      });
    },
    '$route.meta.country': {
      handler: function(search) {
        if (this.selectedMonth) {
          this.$store.dispatch('reports/getLeadingIndicators', {
            country: this.$route.meta.country,
            month: this.selectedMonth,
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(246, 249, 252, 1);
}

div.el-table tbody td, div.el-table thead th {
    padding: 0.5rem;
}

</style>
